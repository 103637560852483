(function () {
    'use strict';

    angular.module('informaApp')
        .service('ArrayExtension', ArrayExtension);

    function ArrayExtension() {
        return {
            setup: function () {
                Object.defineProperty(Array.prototype, 'last', {
                    value: getLastElement,
                    enumerable: false
                });

                Object.defineProperty(Array.prototype, 'removeIf', {
                    value: removeIf,
                    enumerable: false
                });
            }
        }
    }

    function removeIf(shouldBeRemoved) {
        let i = this.length;

        while (i--) {
            if (shouldBeRemoved(this[i], i)) {
                this.splice(i, 1);
            }
        }
    }

    function getLastElement() {
        return this[this.length - 1];
    }
})();
